import React, { useEffect, useState } from "react";

import { Breadcrumb, NewsCard } from "../components";
import ReactPaginate from "react-paginate";

const MoreNews = props => {
  const { data } = props;

  const breadcrumbData = [
    {
      id: 1,
      name: "Home",
      link: "/",
    },
    {
      id: 2,
      name: "All News",
    },
  ];

  function Items({ currentItems }) {
    return (
      <div className="items">
        <div className="row">
          {currentItems?.map(item => {
            return (
              <div className=" col-12 col-md-6">
                <NewsCard key={currentItems.uuid} data={item} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  function PaginatedItems({ itemsPerPage }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(data.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = event => {
      const newOffset = (event.selected * itemsPerPage) % data.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          nextLabel=">>"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="<<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </>
    );
  }

  return (
    <div className="detail">
      <div className="container">
        <Breadcrumb data={breadcrumbData} />
        <h3 className="detail__title">All News</h3>
        <div id="container">
          <PaginatedItems itemsPerPage={2} />
        </div>
      </div>
    </div>
  );
};

export default MoreNews;
