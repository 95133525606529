import React from "react";

import Layout from "../components/layout";
import { Seo } from "../components";

import MoreNews from "../container/more-news";
import { BACKEND_BASE_URL } from "../constant/constants";

const MoreNewsPage = ({ serverData, location }) => {
  return (
    <Layout location={location}>
      <Seo title="More News" />
      <MoreNews data={serverData.news} />
    </Layout>
  );
};

export async function getServerData(context) {
  try {
    const news = await fetch(BACKEND_BASE_URL + "news");

    if (!news.ok) {
      throw new Error(`Response failed`);
    }

    return {
      props: {
        news: await news.json(),
      },
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export default MoreNewsPage;
